<template>
  <div>
    <v-menu
      v-model="menu"
      :nudge-width="200"
      offset-x
      close-on-content-click
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isFloating"
          color="primary"
          fab
          dark
          x-small
          absolute
          top
          left
          elevation="0"
          v-bind="attrs"
          v-on="on"
          style="top: 25px !important;left: 5px"
        >
          <v-icon>mdi-tune</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          dark
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-tune</v-icon> {{ $t('common.settings', locale) }}
        </v-btn>
      </template>

      <v-card class="simulation-card-settings">
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="hideSimulation"
                color="primary"
                @change="handleChangeHideSimulation"
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('dtouch.simulation.hideSimulation', locale) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="showHideContent"
                color="primary"
                @change="handleChangeShowHideContent"
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('dtouch.simulation.showHideContent', locale) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="showFullSimulation"
                color="primary"
                @change="handleChangeShowFullSimulation"
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('dtouch.simulation.showFullSimulation', locale) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="showFastEdit"
                color="primary"
                @change="handleChangeShowFastEdit"
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('dtouch.simulation.showFastEdit', locale) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="allowSort"
                color="primary"
                @change="handleChangeAllowSort"
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('dtouch.simulation.allowSort', locale) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="hideSettingForm"
                color="primary"
                @change="handleChangeHideSettingForm"
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ $t('dtouch.simulation.hideSettingForm', locale) }}
            </v-list-item-title>
          </v-list-item>

        </v-list>
        <v-divider v-if="editedID" />
        <v-layout wrap
          v-if="formatedLangs"
          class="simulation-languages-settings"
        >
          <v-flex xs12
            class="title"
          >
            {{ $t('dtouch.simulation.languages', locale) }}
          </v-flex>
          <v-flex xs12
            class="actions"
          >
            <a
              v-for="(item,i) in formatedLangs"
              :key="i"
              style="font-size: 18px !important;"
              @click.stop.prevent="handleChangeLocale(item.ID)"
            >
              <span
                :style="{'font-weight': item.ID === dtouchSimulationLocale ? 'bold' : 'normal'}"
              >
              {{ item.Name.split('-')[0] }}
              </span>
              <span
                v-if="i+1 < formatedLangs.length"
              >
                {{ ' | ' }}
              </span>
            </a>
          </v-flex>
        </v-layout>
        <workspace-setting
          :locale="locale"
        />
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import WorkspaceSetting from './WorkspaceSetting'
export default {
  name: 'FloatingSettings',
  components: {
    WorkspaceSetting,
  },
  props: {
    languages: {
      type: Array,
      default: null,
    },
    editedID: {
      type: String,
      default: null,
    },
    isFloating: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    formatedLangs: null,
    menu: null,
    hideSimulation: false,
    showHideContent: false,
    showFullSimulation: true,
    showFastEdit: false,

    allowSort: true,
    hideSettingForm: false,
  }),
  computed: {
    ...mapState('app',['locale', 'dtouchSimulationLocale', 'dtouchSimulationHide', 'dtouchSimulationShowContentHide', 'dtouchSimulationShowFullSimulation', 'dtouchSimulationShowFastEdit']),
  },
  watch: {
    languages () {
      this.prepareData()
    },
  },
  mounted () {
    this.showHideContent = this.dtouchSimulationShowContentHide
    this.hideSimulation = this.dtouchSimulationHide
    this.showFullSimulation = this.dtouchSimulationShowFullSimulation

    // update fast edit with localStorage
    this.showFastEdit = localStorage.getItem('dtouchSimulationShowFastEdit') && localStorage.getItem('dtouchSimulationShowFastEdit') == 'true' ? true : false
    this.handleChangeShowFastEdit()

    this.prepareData()
  },
  methods: {
    prepareData () {
      this.formatedLangs = utils.getAllLanguages(this.languages)
      if (!this.dtouchSimulationLocale) {
        if (this.formatedLangs.filter(x => x.ID === this.locale).length > 0) this.handleChangeLocale (this.locale)
        else if (this.formatedLangs.length > 0) this.handleChangeLocale(this.formatedLangs[0].ID)
        else this.handleChangeLocale('es')
      }
    },
    handleChangeHideSimulation () {
      this.$store.state.app.dtouchSimulationHide = this.hideSimulation
    },
    handleChangeShowHideContent () {
      this.$store.state.app.dtouchSimulationShowContentHide = this.showHideContent
    },
    handleChangeShowFullSimulation () {
      this.$store.state.app.dtouchSimulationShowFullSimulation = this.showFullSimulation
    },
    handleChangeShowFastEdit () {
      localStorage.setItem('dtouchSimulationShowFastEdit', this.showFastEdit)
      this.$store.state.app.dtouchSimulationShowFastEdit = this.showFastEdit
    },
    handleChangeAllowSort () {
      this.$store.state.app.dtouchSimulationAllowSort = this.allowSort
    },
    handleChangeHideSettingForm () {
      this.$store.state.app.dtouchHideDtouchSettingForm = this.hideSettingForm
    },
    handleChangeLocale (v) {
      this.$store.state.app.dtouchSimulationLocale = v
    },
  },
}
</script>
<style scoped>
.v-menu__content {
  margin-left: 10px;
  min-height: 450px;
}
.simulation-languages-settings {
  padding-bottom: 20px;
}
.simulation-languages-settings .title {
  text-align: center;
  text-transform: uppercase;
}
.simulation-languages-settings .actions {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}
</style>

